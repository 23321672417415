import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios';
import QS from 'qs';


Vue.use(Vuex)


const myStore = new Vuex.Store({
  state: {
    oid: localStorage.getItem('oid') || '',//机构id，地址栏获取
    orgData: localStorage.getItem('orgData')?JSON.parse(localStorage.getItem('orgData')):'',//机构信息
    orgCover: localStorage.getItem('orgData')?JSON.parse(localStorage.getItem('orgData')).detail.orgCover:'',
    orgLogo: localStorage.getItem('orgData')?JSON.parse(localStorage.getItem('orgData')).detail.orgLogo:'',

    key: '',//验证码vkey
    token: localStorage.getItem('SSMH_TOKEN') || '',
    userInfo: localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')):'',//用户信息
    //baseHost: 'http://192.168.0.152:8989',//测试环境
    //baseHost: 'http://192.168.0.108:8989',//测试环境
    baseHost: 'http://www.cxcyedu.com',//正式环境

    saishiInfo: (localStorage.getItem('saishiInfo')?JSON.parse(localStorage.getItem('saishiInfo')):''),
    
    scrollT: (sessionStorage.getItem('scrollT')?sessionStorage.getItem('scrollT'):''), //评审列表页滚动条高度
    position: '',

    kcMajor: '', //课程专业
  },
  getters:{
    aScrollY: state => {
      return state.position
    }
  },
  mutations: {
    // 更新oid
    updateOid(state,value){
      localStorage.setItem('oid',value);
      state.oid = value;
    },
    //更新机构信息
    updateOrg(state,data){
      localStorage.setItem('orgData',JSON.stringify(data));
      state.orgData = data;
      state.orgCover = data.detail.orgCover;
      state.orgLogo = data.detail.orgLogo;
    },
    //清空机构信息
    clearOrg(state){
      localStorage.removeItem('orgData');
      state.orgData = null;
    },
    // 更新key
    updateKey(state,value){
      state.key = value;
    },
    //更新用户信息
    updateUserInfo(state,data){
      localStorage.setItem('userInfo',JSON.stringify(data));
      state.userInfo = data;
    },
    // 更新头像地址
    updateUserInfoPhotoUrl(state,value){
      let userInfo = state.userInfo;
      userInfo.memberPhotourl = value;
      state.userInfo = userInfo;
      localStorage.setItem('userInfo',JSON.stringify(userInfo));
    },
    // 登录
    LOG_IN(state,data){
      localStorage.setItem('SSMH_TOKEN',data.token);
      localStorage.setItem('userInfo',JSON.stringify(data.user));
      state.token = data.token;
      state.userInfo = data.user;
    },
    // 登出
    LOG_OUT(state){
      localStorage.removeItem('SSMH_TOKEN');
      localStorage.removeItem('userInfo');
      // localStorage.removeItem('saishiInfo');
      state.token = '';
      state.userInfo = '';
      // state.saishiInfo = '';
    },
    updateSaiShiInfo(state,data){
      localStorage.setItem('saishiInfo',JSON.stringify(data));
      state.saishiInfo = data;
    },
    updateCurrentProj(state,data){
      state.currentProj = data;
    },
    updateJudgerConfirm(state){
      state.saishiInfo.judgerConfirm = 1
    },
    saveScrollTop(state,data){
      sessionStorage.setItem('scrollT',data);
      state.scrollT = data;
    },
    clearScrollTop(state){
      sessionStorage.removeItem('scrollT');
      state.scrollT = '';
    },
    // setkeepAlives(state,data){
    //   state.keepAlives = data;
    // }
    changeaScrollY(state,data){
      state.position = data;
    },
    // 更新课程专业
    updateMajor(state,value){
      state.kcMajor = value;
    },
  },
  actions: {
    atc(context){
      if(context.state.old==null || context.state.old==''){
        //根据域名获取机构编码
        var hostname = window.location.hostname;
        var code = hostname.substring(0, hostname.indexOf('.'));
        axios
          .post(context.state.baseHost+'/api/appuser/getOrgId', QS.stringify({ orgCode: code }))
          .then((response) => {
            var res = response.data;
            if(res.result){
              context.commit('updateOid', res.resultData);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        }
    }
  },
  modules: {}
})

myStore.dispatch('atc');


export default myStore;

