import axios from 'axios';
import QS from 'qs';
// import { Toast } from 'vant';

import router from '../router'
import store from '../store'

// import Vue from 'vue';
// import {  Message } from 'element-ui';

// Vue.use(Message);

// 环境的切换
// if (process.env.NODE_ENV == 'development') {
// } else if (process.env.NODE_ENV == 'debug') {
//     axios.defaults.baseURL = 'http://192.168.0.244:8099/api';
// } else if (process.env.NODE_ENV == 'production') {
//     axios.defaults.baseURL = 'http://www.cxcyedu.com';
// }

axios.defaults.baseURL = store.state.baseHost;


// 请求超时时间
axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
// http request 拦截器
axios.interceptors.request.use(
    config => {
        if (localStorage.SSMH_TOKEN) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
            // config.headers.Authorization = `token ${localStorage.JWT_TOKEN}`;
            config.headers.token = `${localStorage.SSMH_TOKEN}`;
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    });

// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.resultData === "timeout" || response.data.resultMsg === "未找到认证令牌") {
            //Message.error('登录过期，请重新登录')
            store.commit('LOG_OUT');
            var oid = localStorage.getItem('oid');
            var pathUrl = "/login/"+oid;
            if(oid==72){
                pathUrl = "/js/login/"+oid;
            }else if(oid==110){
                pathUrl = "/gzzj/login/"+oid;
            }
            router.replace({
                path: pathUrl,
                query: { redirect: router.currentRoute.fullPath }
            });
            return
        }
        return response;
    },
    // 服务器状态码不是200的情况
    error => {
        if (error.response) {
            switch (error.response.status) {
                // 401: 未登录                
                // 未登录则跳转登录页面，并携带当前页面的路径                
                // 在登录成功后返回当前页面，这一步需要在登录页操作。                
                case 401:
                    store.commit('LOG_OUT');
                    router.replace({
                        path: '/login/'+localStorage.getItem('oid'),
                        query: { redirect: router.currentRoute.fullPath }
                    });
                    break;
                // 403 token过期                
                // 登录过期对用户进行提示                
                // 清除本地token和清空vuex中token对象                
                // 跳转登录页面                
                case 403:
                    // Toast({
                    //     message: '登录过期，请重新登录',
                    //     duration: 1000,
                    //     forbidClick: true
                    // });
                    // 清除token                    
                    store.commit('LOG_OUT');
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    setTimeout(() => {
                        router.replace({
                            path: '/login/'+localStorage.getItem('oid'),
                            query: {
                                redirect: router.currentRoute.fullPath
                            }
                        });
                    }, 1000);
                    break;
                // 404请求不存在                
                case 404:
                    // Toast({
                    //     message: '网络请求不存在',
                    //     duration: 1500,
                    //     forbidClick: true
                    // });
                    break;
                // 其他错误，直接抛出错误提示                
                default:
                    // Ttoast({
                    //     message: error.response.data.message,
                    //     duration: 1500,
                    //     forbidClick: true
                    // });
            }
            return Promise.reject(error.response);
        }
    }
);
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}